<template lang="pug">
  div
    base-snackbar(
      :errors.sync="feedingProgramDetailDeleteErrors"
    )
    base-snackbar(
      :errors.sync="feedingProgramDetailGetErrors"
    )
    v-data-table(
      :items="feedingProgramDetailList"
      :headers="headers"
      :loading="feedingProgramDetailGetting"
      dense
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.day }}
          td {{ (item.feed_per_day_kg || 0).unitFormat('kilogram') }}
          td
            v-btn(
              color="yellow darken-3"
              dense
              text
              x-small
              @click="$emit('edit', item)"
            )
              v-icon mdi-pencil
            v-btn(
              color="error"
              dense
              text
              x-small
              :loading="feedingProgramDetailDeleting"
              @click="removeItem(item.id)"
            )
              v-icon mdi-delete
</template>
<script>
import VueRequest from '@/libs/classes/VueRequest.class'
import { getVars } from '@/libs/api-helper.extra'
import feedingProgramDetailRepository from '@/repositories/feeding-program-detail.repository'

const tableVars = () => ({
  headers: [
    { text: 'Day / Age', value: 'day' },
    { text: 'Feeds / Kg', value: 'feed_per_day_kg' },
    { text: 'Config', sortable: false },
  ],
})

const getRequestVarNames = baseName => ({
  loading: `${baseName}Getting`,
  error: `${baseName}GetErrors`,
  list: `${baseName}List`,
})

export default {
  name: 'FeedingProgramCategoryTable',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    ...tableVars(),
    ...getVars('feeding-program-detail'),
    feedingProgramDetailDeleting: false,
    feedingProgramDetailDeleteErrors: {},
  }),
  created() {
    this.getFeedingProgramDetail()
    this.listenToWebsocketEvents()
  },
  watch: {
    feedingProgramDetailList: {
      handler() {
        this.updateLastRow()
      },
      deep: true,
    },
  },
  methods: {
    updateLastRow() {
      const lastDay = this.getLastDay()
      this.$emit('input', lastDay)
    },
    getLastDay() {
      return this.feedingProgramDetailList.reduce((result, { day }) => {
        if (result < day) result = day
        return result
      }, 0)
    },
    getFeedingProgramDetail() {
      if (this.feedingProgramDetailGetting) return
      const requestVarNames = getRequestVarNames('feedingProgramDetail')
      const handler = new VueRequest(this, requestVarNames)
      const repository = feedingProgramDetailRepository.index
      const { feedingProgramId } = this.$route.params
      handler.execute(repository, [feedingProgramId])
    },
    removeItem(id) {
      this.$confirm('Do you really want to remove this item ?')
        .then(confirm => {
          if (!confirm) return
          this.deleteFeedingProgramDetail(id)
        })
    },
    deleteFeedingProgramDetail(id) {
      if (this.feedingProgramDetailDeleting) return
      const requestVarNames = { loading: 'feedingProgramDetailDeleting', error: 'feedingProgramDetailDeleteErrors' }
      const handler = new VueRequest(this, requestVarNames, { hasList: false })
      const repository = feedingProgramDetailRepository.delete
      handler.execute(repository, [id])
    },
    listenToWebsocketEvents() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
        .listen('DBDeleteEvent', this.dbDeleteEvent)
    },
    dbStoreEvent({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'FeedingProgramDetail') {
        this.feedingProgramDetailDBStoreEvent(data)
      }
    },
    dbUpdateEvent({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'FeedingProgramDetail') {
        this.feedingProgramDetailDBUpdateEvent(data)
      }
    },
    feedingProgramDetailDBUpdateEvent(data) {
      this.feedingProgramDetailList = this.feedingProgramDetailList.map(item => {
        if (item.id === data.id) return { ...data }
        return { ...item }
      })
    },
    feedingProgramDetailDBStoreEvent(data) {
      data = this.$objectAttributesToNumeric(data)
      this.feedingProgramDetailList.push(data)
    },
    dbDeleteEvent({ id, model }) {
      id = Number(id)
      if (!id || !model) return
      if (model === 'FeedingProgramDetail') {
        this.feedingProgramDetailDBDeleteEvent(id)
      }
    },
    feedingProgramDetailDBDeleteEvent(id) {
      this.feedingProgramDetailList = this.feedingProgramDetailList.filter(item => item.id !== id)
    },
  },
}
</script>
